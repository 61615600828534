import { css } from 'styled-components';

import { isBreakpoint } from '@/lib/breakpoints';

import mq from '@/styles/js/utils/mq';

import fontSmoothing from './smoothing';
import fontStack from './stacks';

/*
 * Type styles definition. This is a convenient adaptation
 * of one-sass-toolkit to make our conversion effort easier.
 */
const TYPE_STYLES = {
  mega: {
    stack: 'campton',
    smoothing: true,
    sizes: {
      default: 28,
      medium: 52,
      large: 60,
    },
    properties: css`
      line-height: 1.15;
      font-weight: bold;
    `,
  },
  h1: {
    stack: 'campton',
    smoothing: true,
    sizes: {
      default: 25,
      medium: 28,
    },
    properties: css`
      line-height: 1.25;
      font-weight: bold;
    `,
  },
  h2: {
    stack: 'campton',
    smoothing: true,
    sizes: {
      default: 22,
    },
    properties: css`
      line-height: 1.3;
      font-weight: bold;
    `,
  },
  h3: {
    stack: 'campton',
    smoothing: true,
    sizes: {
      default: 16,
      medium: 20,
    },
    properties: css`
      line-height: 1.3;
      font-weight: bold;
    `,
  },
  h4: {
    stack: 'campton',
    smoothing: true,
    sizes: {
      default: 16,
    },
    properties: css`
      line-height: 1.3;
      font-weight: bold;
    `,
  },
  body: {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 13,
      medium: 15,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  'body-xl': {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 18,
      medium: 22,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  'body-l': {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 15,
      medium: 18,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  'body-ml': {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 14,
      medium: 15,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  'body-m': {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 13,
      medium: 14,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  'body-s': {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 13,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  mini: {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 12,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  micro: {
    stack: 'averta-bold',
    smoothing: false,
    sizes: {
      default: 10,
    },
    properties: css`
      line-height: 1.6;
      text-transform: uppercase;
      letter-spacing: 0.06em;
    `,
  },
  button: {
    stack: 'averta-bold',
    smoothing: true,
    sizes: {
      default: 16,
    },
    properties: css`
      line-height: 1.3;
    `,
  },
  'button-l': {
    stack: 'campton',
    smoothing: true,
    sizes: {
      default: 18,
    },
    properties: css`
      line-height: 1.3;
    `,
  },
  'button-s': {
    stack: 'averta-bold',
    smoothing: false,
    sizes: {
      default: 13,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
  'form-input': {
    stack: 'averta',
    smoothing: false,
    sizes: {
      default: 16,
    },
    properties: css`
      line-height: 1.6;
    `,
  },
};

/*
 * Spits out CSS font properties based on the TYPE_STYLES map definition.
 */
const typeStyle = (type, size = 'default') => {
  const currentType = TYPE_STYLES[type];

  if (!currentType) {
    throw new Error(`Provided type: "${type}" is invalid on typeStyle.`);
  }

  const { stack, smoothing, sizes, properties } = currentType;

  const currentSize = sizes[size];

  // TODO this only works if the body,html font-size is 10px
  const rem = (val) => `${val / 10}rem`;

  if (!currentSize) {
    throw new Error(`"TYPE_STYLES.${type}.sizes.${size}" is not defined.`);
  }

  const renderMQs = () => {
    if (sizes.length === 1) return '';

    return Object.keys(sizes)
      .map((key) => {
        if (key === 'default') return '';

        if (!isBreakpoint(key))
          throw new Error(`"${key}" is not a valid breakpoint.`);

        return css`
          ${mq(key)} {
            font-size: ${rem(sizes[key])};
          }
        `;
      })
      .join('\n');
  };

  return css`
    ${fontStack(stack)}
    ${smoothing ? fontSmoothing : ''}
    ${properties ? properties : ''}

    font-size: ${rem(currentSize)};

    ${renderMQs()}
  `;
};

export default typeStyle;
