import { forwardRef, memo } from 'react';

import Icon from '@/components/graphics/Icon';

import { Link } from '@/lib/next';

const defaultPropValues = {
  buttonProps: {},
  disabled: false,
  className: '',
  buttonType: 'button',
  newPage: false,
};

/**
 * A flexible component for creating interactive elements
 * with common properties. CommonLink will automatically display
 * a <a> element, Link component, or <button> element, depending
 * on the passed props. This component is intended to be used
 * internally by other interactive components and not directly.
 */
const CommonLink = memo(
  forwardRef((providedProps, ref) => {
    const props = { ...defaultPropValues, ...providedProps };

    const renderContents = () => (
      <>
        {props.iconBefore ? <Icon type={props.iconBefore} /> : null}
        {!!props.children && <span>{props.children}</span>}
        {props.iconAfter ? <Icon type={props.iconAfter} /> : null}
      </>
    );

    const sharedProps = {
      className: props.className,
      disabled: props.disabled,
      ...(props.onClick && { onClick: props.onClick }),
      ref,
    };

    let newPageProps = {};
    if (props.newPage) {
      newPageProps.target = '_blank';
      newPageProps.rel = 'noopener noreferrer';
    }

    if (props.href) {
      return (
        <Link
          {...newPageProps}
          scroll={props.scroll}
          href={props.href}
          {...sharedProps}
        >
          {renderContents()}
        </Link>
      );
    }

    // Fallback <button>
    return (
      <button {...sharedProps} {...props.buttonProps} type={props.buttonType}>
        {renderContents()}
      </button>
    );
  }),
);

export { CommonLink };
