import typeStyle from '@/styles/js/fonts/typeStyles';
import styled, { css } from 'styled-components';

/**
 * Styled StripeSecure
 *
 * A message assuring the user that their payment is secure.
 */
export const StripeSecure = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 39.5rem;

  ${({ $alt }) => $alt && css`
    flex-direction: column;
    justify-content: center;
    margin: 3rem auto 0;

    > p {
      margin: 0;
    }
  `}

  > p {
    ${typeStyle('body-s')}

    color: ${({ theme }) => theme.colors.textLight};
    margin-right: 2rem;
  }
`;

export const StripeSecureBadge = styled.div`
  ${({ $alt }) => $alt && css`
    margin-bottom: 1rem;
  `}

  > svg {
    width: 11.9rem;
    height: 2.6rem;
  }
`;
