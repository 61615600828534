import PropTypes from 'prop-types';

import { cssPxCoercion } from '@/styles/js/utils/units';

/**
 * Creates an <svg> element that displays
 * an svg symbol in an external file by its `id`
 */
const SvgUse = ({
  id,
  width,
  height,
  color,
  category = 'icons',
  ...moreStyles
}) => {
  let styles = {};

  if (width) styles.width = cssPxCoercion(width);
  if (height) styles.height = cssPxCoercion(height);
  if (color) styles.color = color;

  return (
    <svg
      viewBox={width && height ? `0 0 ${width} ${height}` : null}
      style={{
        ...moreStyles,
        ...styles,
      }}
    >
      <use href={`/svgs/${category}.sprite.svg#${category}/${id}`} />
    </svg>
  );
};

const unitType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

SvgUse.propTypes = {
  id: PropTypes.string,
  category: PropTypes.oneOf(['icons', 'full']),
  width: unitType,
  height: unitType,
  color: PropTypes.string,
};

export default SvgUse;
