import NextLink from 'next/link';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { forwardRef, useMemo } from 'react';

import { useMountLayout } from '@/hooks/useMount';

import qs from '@/lib/qs';

export function useParsedSearch() {
  const urlSearchParams = useSearchParams();
  const stringParams = urlSearchParams.toString();

  return useMemo(() => {
    return qs.parse(stringParams, { ignoreQueryPrefix: false });
  }, [stringParams]);
}

export const Link = forwardRef(({ href, ...props }, ref) => {
  /*
   * FIXME evaluate if this is necessary in the end of this PR.
   * NOTE I made this to help track issues in the conversion to next/link
   */
  if (!href) {
    /* eslint-disable no-console */
    console.trace('Link component requires an href prop');
    return null;
  }

  return <NextLink ref={ref} {...props} href={href} />;
});

// FIXME we might not need this with next.js 15
export const StyledNextLink = forwardRef(
  ({ href, children, ...props }, ref) => (
    <NextLink ref={ref} href={href} passHref legacyBehavior>
      <a {...props}>{children}</a>
    </NextLink>
  ),
);

// FIXME Re-evaulate this component after the refactor
export const Redirect = ({ href }) => {
  const router = useRouter();
  useMountLayout(() => {
    router.replace(href);
  });

  return null;
};

// FIXME This is only necessary until we refactor the old class components
export const withRouter = (Component) => {
  return (props) => {
    const router = useRouter();
    const params = useParams();

    return <Component {...props} pathParams={params} router={router} />;
  };
};
